<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range" :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Outlet</label>
                    <Dropdown id="outlet_type_id" dataKey="outlet_type_id" v-model="filters.outlet_type_id" :loading="loadingDropdownTipeOutlet"
                        :options="dataDropdownTipeOutlet"
                        optionLabel="outlet_type_name" optionValue="outlet_type_id" placeholder="Pilih Tipe Outlet"
                        :filter="true" :showClear="true" @filter="searchDropdownTipeOutlet($event, 'filter')"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Status Approval</label>
                     <Dropdown id="status_approval" v-model="filters.status_approval" :options="dataDropdownStatusApproval" optionLabel="name" optionValue="code" placeholder="Pilih Status" />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Area</label>
                    <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="filters.provinsi_id" :loading="loadingDropdownProvinsi"
                        :options="dataDropdownProvinsi"
                        optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
                        :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'filter')"
                        @change="changeKabupaten()" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Kabupaten/Kota</label>
                    <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="filters.kabupaten_id" :loading="loadingDropdownKabupaten"
                        :options="dataDropdownKabupaten"
                        optionLabel="kabupaten_name" optionValue="kabupaten_id" placeholder="Pilih Kabupaten/Kota"
                        :filter="true" :showClear="true" @filter="searchDropdownKabupaten($event, 'filter')"
                        @change="changeSubDist()" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Sub Dist</label>
                    <Dropdown id="subdist_id" dataKey="subdist_id" v-model="filters.subdist_id" :loading="loadingDropdownSubdist"
                        :options="dataDropdownSubdist"
                        optionLabel="label" optionValue="subdist_id" placeholder="Pilih Sub Dist" :filter="true"
                        :showClear="true" @filter="searchDropdownSubDist($event, 'filter')"
                        @change="changeSalesman()" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Salesman</label>
                    <Dropdown id="salesman_id" dataKey="salesman_id" v-model="filters.salesman_id" :loading="loadingDropdownSalesman"
                        :options="dataDropdownSalesman"
                        optionLabel="label" optionValue="salesman_id" placeholder="Pilih Salesman" :filter="true"
                        :showClear="true" @filter="searchDropdownSalesman($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,
            loadingDropdownSubdist: false,
            loadingDropdownSalesman: false,
            loadingDropdownTipeOutlet: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,
            dataDropdownSubdist: null,
            dataDropdownSalesman: null,
            dataDropdownTipeOutlet: null,
            dataDropdownStatusApproval: [
                {name: 'Pending', code: 'p'},
                {name: 'Terima', code: 'y'},
                {name: 'Tolak', code: 'n'},
            ],

            // filter
            filters: {
                provinsi_id: null,
                kabupaten_id: null,
                subdist_id: null,
                salesman_id: null,
                status_approval: 'p',
                outlet_type_id: null,
            },

            period: [new Date(), new Date()],
            minDate: null,
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownProvinsi('');
        this.searchDropdownKabupaten('');
        this.searchDropdownSubDist('');
        this.searchDropdownSalesman('');
        this.searchDropdownTipeOutlet('');
        // this.minDate =  new Date();
        // this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth() + 2, 0);
    },
    created(){
        // set awal tanggal bulan berjalan
        this.period[0].setDate(1);


        // set akhir tanggal bulan berjalan
        this.period[1].setMonth(this.period[1].getMonth() + 1);
        this.period[1].setDate(0);
    },
    watch: {
        // period(){
        //     //min hari ini
        //     this.minDate =  new Date();
        //     //max range 30 days
        //     this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth() + 2, 0);
        // }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownTipeOutlet(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dtpoutlet.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownTipeOutlet = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/tipe-outlet',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownTipeOutlet = res.data.data;
                            this.loadingDropdownTipeOutlet = false;
                        } else if (purpose == null) {
                            this.dataDropdownTipeOutlet = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeKabupaten() {
            this.filters.kabupaten_id = null;
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');

            this.filters.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.filters.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');
        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dkab.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownKabupaten = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/kota',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownKabupaten = res.data.data;
                        this.loadingDropdownKabupaten = false;
                    } else if (purpose == null) {
                        this.dataDropdownKabupaten = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeSubDist() {
            this.filters.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.filters.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');
        },
        searchDropdownSubDist(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.filters.provinsi_id && this.filters.kabupaten_id) {

                    if (valueEdit) {
                        this.$refs.dsubdist.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownSubdist = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/subdist',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.filters.provinsi_id,
                            "kabupaten_id": this.filters.kabupaten_id,
                        }
                    })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownSubdist = res.data.data;
                            this.loadingDropdownSubdist = false;
                        } else if (purpose == null) {
                            this.dataDropdownSubdist = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        changeSalesman() {
            this.filters.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');
        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dsalesman.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownSalesman = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/salesman',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "provinsi_id": this.filters.provinsi_id,
                        "kabupaten_id": this.filters.kabupaten_id,
                        "subdist_id": this.filters.subdist_id,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownSalesman = res.data.data;
                            this.loadingDropdownSalesman = false;
                        } else if (purpose == null) {
                            this.dataDropdownSalesman = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

