<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getFilter()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="noo_id" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                        <div class="mt-3" v-if="forms.noo_id.length > 0">
                            <div class="p-fluid">
                                <div class="formgrid grid">
                                    <div class="field col-12 md:col-3 lg:col-3">
                                        <Dropdown v-model="forms.status_approval" :options="dataDropdownStatusApproval" optionLabel="name" optionValue="code" placeholder="Pilih Status" />
                                    </div>
                                    <div class="field col-12 md:col-3 lg:col-3" v-if="forms.status_approval == 'n'">
                                        <InputText v-model="forms.reject_reason" autofocus :class="{ 'p-invalid': errorAdd.reject_reason }" placeholder="Tuliskan Alasan"/>
                                        <small class="p-invalid" style="color: red" v-if="errorAdd.reject_reason" >{{ errorAdd.reject_reason[0] }}</small >
                                    </div>
                                </div>
                            </div>
                            <Button :loading="loadingAdd" label="Submit" icon="pi pi-save" class="p-button p-button-info mr-2 my-1 inline-block" @click="saveNew()" />
                        </div>
                    </template>
                    <Column field="noo_id" header="">
                        <template #body="slotProps">
                             <Checkbox v-model="forms.noo_id" :inputId="slotProps.data.noo_id" name="noo" :value="slotProps.data.noo_id" v-if="slotProps.data.is_approved_label == 'Pending'"/>
                        </template>
                    </Column>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="created_at" header="Waktu Input">
                        <template #body="slotProps">
                            {{ formatDate(slotProps.data.created_at)}}
                        </template>
                    </Column>
                    <Column field="salesman_name" header="Salesman">
                        <template #body="slotProps">
                            {{slotProps.data.salesman_name}}
                        </template>
                    </Column>
                    <Column field="outlet_name" header="Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_name}}
                        </template>
                    </Column>
                    <Column field="pemilik_name" header="Pemilik">
                        <template #body="slotProps">
                            {{slotProps.data.pemilik_name}}
                        </template>
                    </Column>
                    <Column field="no_hp" header="No. Hp">
                        <template #body="slotProps">
                            {{slotProps.data.no_hp}}
                        </template>
                    </Column>
                    <Column field="alamat" header="Alamat Input App">
                        <template #body="slotProps">
                            {{slotProps.data.alamat}}
                        </template>
                    </Column>
                    <Column field="outlet_type_name" header="Tipe Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.outlet_type_name}}
                        </template>
                    </Column>
                    <Column field="Photo" header="Photo">
                        <template #body="slotProps">
                            {{slotProps.data.Photo}}
                        </template>
                    </Column>
                    <Column field="provinsi_name" header="Provinsi">
                        <template #body="slotProps">
                            {{slotProps.data.provinsi_name}}
                        </template>
                    </Column>
                    <Column field="kabupaten_name" header="Kabupaten">
                        <template #body="slotProps">
                            {{slotProps.data.kabupaten_name}}
                        </template>
                    </Column>
                    <Column field="kecamatan_name" header="Kecamatan">
                        <template #body="slotProps">
                            {{slotProps.data.kecamatan_name}}
                        </template>
                    </Column>
                    <Column field="kelurahan_name" header="Kelurahan">
                        <template #body="slotProps">
                            {{slotProps.data.kelurahan_name}}
                        </template>
                    </Column>
                    <Column field="koordinat" header="Koordinat">
                        <template #body="slotProps">
                            <a :href="'https://maps.google.com/maps?q=loc:' + slotProps.data.koordinat" target="_blank"> {{ slotProps.data.koordinat }}</a>
                        </template>
                    </Column>
                    <Column field="is_approved_label" header="Status Approval">
                        <template #body="slotProps">
                            {{slotProps.data.is_approved_label}}
                        </template>
                    </Column>
                    <Column field="approved_time" header="Waktu Approve">
                        <template #body="slotProps">
                            {{slotProps.data.approved_time}}
                        </template>
                    </Column>
                    <Column field="reject_reason" header="Alasan Tolak">
                        <template #body="slotProps">
                            {{slotProps.data.reject_reason}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/master/noo/Filter.vue';

export default {
    components: {
		'Filter': Filter,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingAdd: false,

            dataDropdownStatusApproval: [
                {name: 'Terima', code: 'y'},
                {name: 'Tolak', code: 'n'},
            ],

            // forms
            forms: {
                status_approval: 'y',
                reject_reason: null,
                noo_id: [],
            },


            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors', 'errorAdd']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        convertArrayStatusApproval(data) {
            if (data) {
                return data.map(() => {
                    return this.forms.status_approval;
                },(this));
            }
        },
        convertArrayRejectReason(data) {
            if (data) {
                return data.map(() => {
                    return this.forms.reject_reason;
                },(this));
            }
        },
        // ADDNEW
        saveNew() {
            if(this.forms.status_approval == 'n' && this.forms.reject_reason == null){
                alert('Silahkan diisi field yang tersedia');
                return
            }
            this.loadingAdd = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-noo/update',
                data: {
                    "noo_id": this.forms.noo_id,
                    "status_approval": this.convertArrayStatusApproval(this.forms.noo_id),
                    "reject_reason": this.convertArrayRejectReason(this.forms.noo_id),
                },
            })
            .then((res) => {
                this.loadingAdd = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAdd = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.status_approval = 'y';
            this.forms.reject_reason = null;
            this.forms.noo_id= []
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-noo',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "provinsi_id" : this.$refs.filter.filters.provinsi_id,
                    "kabupaten_id" : this.$refs.filter.filters.kabupaten_id,
                    "subdist_id" : this.$refs.filter.filters.subdist_id,
                    "salesman_id" : this.$refs.filter.filters.salesman_id,
                    "status_approval" : this.$refs.filter.filters.status_approval == 'p' ? null : this.$refs.filter.filters.status_approval,
                    "outlet_type_id" : this.$refs.filter.filters.outlet_type_id,
                    "tanggal_mulai" : this.$refs.filter.period_start_label,
                    "tanggal_akhir" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        getFilter(){
            this.forms.noo_id = [];
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-noo/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "provinsi_id" : this.$refs.filter.filters.provinsi_id,
                "kabupaten_id" : this.$refs.filter.filters.kabupaten_id,
                "subdist_id" : this.$refs.filter.filters.subdist_id,
                "salesman_id" : this.$refs.filter.filters.salesman_id,
                "status_approval" : this.$refs.filter.filters.status_approval == 'p' ? null : this.$refs.filter.filters.status_approval,
                "outlet_type_id" : this.$refs.filter.outlet_type_id,
                "tanggal_mulai" : this.$refs.filter.period_start_label,
                "tanggal_akhir" : this.$refs.filter.period_end_label,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master NOO Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
